import { IVisualizationSettings } from './interfaces'

export const DEVELOPMENT = process.env.NODE_ENV === 'development'
export const PRODUCTION = process.env.NODE_ENV === 'production'
export const REACT_APP_I18N_DEBUG = DEVELOPMENT && process.env.REACT_APP_I18N_DEBUG === 'true'

export enum PATH {
  SIGNIN = '/signin',
  RESTORE_PASSWORD = '/restore-password',
  NEW_PASSWORD = '/new-password',
  MISSION_LIST = '/',
  MISSION = '/mission/',
  MISSION_ID = '/mission/:id',
  ROBOT_LIST = '/asset',
  ROBOT = '/asset/:id',
  MISSION_CONTROL = '/mission-control/:id',
  ERROR = '/error',
  NOT_FOUND = '/404',
  EMPTY = '',
  OTHERS = '*',
}

export enum PROGRESS_TYPES {
  IDLE = 'idle',
  WORK = 'work',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum LOGIN_ERROR_TYPES {
  NONE,
  INVALID_EMAIL_OR_PASSWORD,
  ERROR,
}

export enum LANGUAGES {
  RU = 'ru',
  EN = 'en',
}

export const enum MISSION_PAGE_MODES {
  VIEW_ZONES = 'view_zones',
  DRAW_ZONE = 'draw_zone',
  EDIT_ZONE = 'edit_zone',
}

export const enum MISSION_MAP_DRAWING_MODES {
  VIEW_ZONES = 'view_zones',
  DRAW_POLYGON = 'draw_polygon',
  DIRECT_SELECT_ZONE = 'direct_select_zone',
  ADD_ZONE_LAUNCH_POINT = 'draw_point',
  DIRECT_SELECT_ZONE_LAUNCH_POINT = 'direct_select_point',
  DIRECT_SELECT_TRANSFER_ROUTE = 'direct_select_transfer_route',
  ADD_MISSION_START_POINT = 'draw_start_point',
  ADD_MISSION_FINISH_POINT = 'draw_finish_point',
  DIRECT_SELECT_MISSION_POINT = 'direct_select_mission_point',
}

export const enum MISSION_ZONE_TYPES {
  CLEANING = 'cleaning',
  RESTRICT = 'restrict',
}

export const enum ROBOT_STATUSES {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'not_available',
}

export const enum MISSION_CALCULATING_STATUSES {
  NEW = 'new',
  CALCULATING = 'calculating',
  OK = 'ok',
  ERROR = 'error',
  PARTIAL_CALCULATED = 'partial_calculated',
  UNKNOWN = 'unknown',
}

export const enum TRANSFER_CALCULATING_STATUSES {
  NEW = 'new',
  CALCULATING = 'calculating',
  OK = 'ok',
  ERROR = 'error',
}

export const enum MISSION_JOB_STATUSES {
  NEW = 'new',
  CALCULATING = 'scenario_calculating',
  OK = 'scenario_done',
  ERROR = 'scenario_error',
}

export const TIMEOUT_FETCHING_STATUS = 3000

export const TOKEN_MAPBOX = 'pk.eyJ1Ijoid2luc2VudCIsImEiOiJjbDdzdDJjeGEwNmI3M3BvMHVtazRzbDNvIn0.Vt0DpZ-ngznh3CiGNJYViQ'

export const enum STYLES {
  VECTOR = 'mapbox://styles/mapbox/streets-v9',
  SATELLITE = 'mapbox://styles/mapbox/satellite-v9',
}

export const enum GEOTIFF_LAYER_STATUSES {
  NEW = 'new',
  ERROR = 'error',
  PENDING = 'pending',
  OK = 'ok',
}

export const enum TRANSFER_TYPE {
  START,
  MIDDLE,
  FINISH,
}

export const enum ROBOT_MODES {
  MODE_NOT_SET = 0,
  MODE_MANUAL = 1,
  MODE_AUTOMATIC = 2, 
  MODE_MAINTENANCE = 3 
}

export const enum ROBOT_COMMANDS {
  START = '1',
  STOP = '2',
  PAUSE = '3',
}

export const enum ROBOT_NETWORK_STATUSES {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export const MAX_TRAJECTORY_POINTS = 1000

export const TRAJECTORY_LINE_WIDTH_IN_METERS = 0.5

export const DEFAULT_VIZUALIZATION_SETTINGS: IVisualizationSettings = {
  clean_fill_color: '#4e78f6',
  clean_border_color: '#4e78f6',
  clean_width: 3,
  clean_opacity: 0.2,
  restrict_fill_color: '#dc664d',
  restrict_border_color: '#DC664D',
  restrict_width: 3,
  restrict_opacity: 0.2,
  cleaning_route_color: '#9747ff',
  cleaning_route_width: 1,
  cleaning_route_opacity: 1,
  transfer_route_color: '#9747FF',
  transfer_route_width: 2,
  transfer_route_opacity: 1,
}

export const enum ROBOT_EQUIPMENT_COMMAND {
  // TRASH = 'trash_lock',
  BODY = 'body_lock',
  // FRONT = 'front_lock',
  // CHARGE = 'charge_lock',
  WATER = 'watering_command',
  LIGHT = 'light_command',
  DANGER = 'danger_button_command',
  CLEANING_EQUIPMENT = 'cleaning_equipment_command',
}

export const MAX_TRAJECTORY_DISTANCE = 0.5
