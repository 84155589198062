import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import { ReactComponent as MissionSvg } from 'images/icons/mission_play.svg'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { generatePath, useNavigate } from 'react-router-dom'
import { selectRobotActiveMode, selectRobotJob, selectRobotJobUploadingProgress } from 'store/selectors/robot'
import store from 'store/store'
import { robotThunks } from 'store/thunks/robot'
import { MISSION_JOB_STATUSES, PATH, PROGRESS_TYPES, ROBOT_MODES } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'

import SelectMissionModal from './SelectMissionModal/SelectMissionModal'

import style from './RobotMissionUploading.module.css'

enum STATES {
  SELECT_MISSION,
  MISSION_READY_TO_START,
  UPLOADING_MISSION_ERROR,
  UPLOADING,
  CALCULATING_SCENARIO,
  TURN_ON_ROBOT,
}

const RobotMissionUploading = ({ robotId }: { robotId?: number }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const uploadingState = useSelector(selectRobotJobUploadingProgress)
  const job = useSelector(selectRobotJob)
  const [showModal, setShowModal] = useState(false)
  const [selectedMission, setSelectedMission] = useState<IMissionInfo | undefined>(undefined)
  const activeMode = useSelector(selectRobotActiveMode)

  const state = useMemo(() => {
    if (uploadingState === PROGRESS_TYPES.ERROR) {
      return STATES.UPLOADING_MISSION_ERROR
    } else if (uploadingState === PROGRESS_TYPES.WORK) {
      return STATES.UPLOADING
    }
    if (job?.status === MISSION_JOB_STATUSES.CALCULATING) {
      return STATES.UPLOADING
    }

    if (activeMode === null || activeMode === undefined) {
      return STATES.TURN_ON_ROBOT
    }

    if (!job) {
      return STATES.SELECT_MISSION
    } else {
      return STATES.MISSION_READY_TO_START
    }
  }, [activeMode, job, uploadingState])

  const styles = {
    [`${STATES.SELECT_MISSION}`]: style.idle,
    [`${STATES.UPLOADING}`]: style.work,
    [`${STATES.CALCULATING_SCENARIO}`]: style.work,
    [`${STATES.UPLOADING_MISSION_ERROR}`]: style.error,
    [`${STATES.MISSION_READY_TO_START}`]: style.success,
    [`${STATES.TURN_ON_ROBOT}`]: style.idle,
  }

  const messages = {
    [STATES.SELECT_MISSION]: t('robot.mission_not_uploaded'),
    [STATES.UPLOADING]: t('robot.mission_uploading'),
    [STATES.UPLOADING_MISSION_ERROR]: t('robot.mission_uploading_error'),
    [STATES.MISSION_READY_TO_START]: `${job?.missionName} (id ${job?.missionId})`,
    [STATES.CALCULATING_SCENARIO]: t('robot.mission_uploading'),
    [STATES.TURN_ON_ROBOT]: t('robot.turn_on'),
  }

  const isUploading = state === STATES.UPLOADING || false

  const onSelectClick = () => {
    setShowModal(true)
  }

  const onStartMissionClick = () => {
    if (job) {
      const path = generatePath(PATH.MISSION_CONTROL, { id: String(job.id) })
      navigate(path)
    }
  }

  const onRetryClick = () => {
    if (selectedMission && robotId) {
      store.dispatch(robotThunks.createJobAndUploadJobToRobot(selectedMission.id, robotId))
    }
  }

  const onMissionSelected = async (mission: IMissionInfo | undefined) => {
    setShowModal(false)
    setSelectedMission(mission)
    if (mission && robotId) {
      store.dispatch(robotThunks.createJobAndUploadJobToRobot(mission.id, robotId))
    }
  }

  const missionInProgress = activeMode !== ROBOT_MODES.MODE_NOT_SET

  return (
    <div className={clsx(style.main, styles[state])}>
      <MissionSvg />
      <div className={style.message} dangerouslySetInnerHTML={{ __html: messages[state] }} />
      {uploadingState === PROGRESS_TYPES.ERROR && (
        <div className={style.retryText}>
          <span className={style.link} onClick={onRetryClick}>
            retry
          </span>{' '}
          or
        </div>
      )}
      {job && !isUploading && (
        <Button.Standart className={style.button} onClick={onStartMissionClick}>
          {missionInProgress ? t('robot.button_go_to_mission') : t('robot.button_mission_start')}
        </Button.Standart>
      )}
      {!isUploading && (
        <Button.Standart className={style.button} onClick={onSelectClick} disabled={missionInProgress}>
          {t('robot.button_mission_select')}
        </Button.Standart>
      )}

      {isUploading && (
        <Button.Standart className={style.button} disabled={true} loading={true}>
          {t('robot.button_mission_uploading')}
        </Button.Standart>
      )}
      <SelectMissionModal
        selectedMissionId={job?.missionId}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirmClick={onMissionSelected}
      />
    </div>
  )
}

export default RobotMissionUploading
